<div id="gearwrapper">
    <h3>My Gear</h3>
    <h4>OTAs & Mounts</h4>
    <ul>
        <li><img src="https://lh3.googleusercontent.com/xopUegnhd74vamflxGeD-wDMn-mPXcGTDjfTteDen4C3JSkels7_pahpbHc2CuSk5vGVGDl9oGf7M7FRGvnJTYwzE-zz0oKwCGJ8-vSyPqpRu65KrW5xXwScrhHm7VlseE1K3y4cyMc">Celestron 9.25" NexStar Evolution Computerized Telescope</li>
        <li><img src="https://lh3.googleusercontent.com/DdcR-KRLduTUR9vlswf7TeJCwqq6-YcXOPkd5TCzplLfqMiobBSFTqVDEI7umEOP0hKS6vOhoJiGdfOMVYjOiqPMkEewCnRNPv09Rx7ZUaVqPDFpVB0Y6IIovG5MjMFCjhPk0PKQ6QQ">Celestron Travel Scope 70 mm Portable</li>
    </ul>
    <h4>Telescope Helpers</h4>
    <ul>
        <li><img src="https://lh3.googleusercontent.com/WlJ2ZWPlNoB3NWsAhDQbsHgsjoPhYmC3Kr1_Ys38Hrfv1hiGrDa73diwP7-EmSUJZJRPcDO4sAhC2ryAo8521i_3HKsCqE3Z5fNHYc7R5W3dKWVXaWt6rT9W5rKvdtcPlhMQShX-kZw">Celestron StarSense Auto Telescope Alignment</li>
        <li><img src="https://lh3.googleusercontent.com/279lq4PuxKHMC2iDntEXRIG5FKhT_lgVzrXt4pHh768PNyDt4X0tQBbwA0sZss4QbBzhdclKFpCsMIkSz4bQ2frjrdeQnF-LAwgBGM5DvQL3_zjSX20DJT8scnqWDSdhnt367MLnS5s">Celestron Focus Motor</li>
    </ul>
    <h4>Eye Pieces</h4>
    <ul>
        <li>Celestron 1.25" 20mm</li>
        <li>Celestron 1.25" 40mm</li>
        <li>Celestron 1.25" 40mm Plossl Eyepiece</li>
        <li>Celestron 1.25" 13mm Plossl Eyepiece</li>
        <li><img src="https://lh3.googleusercontent.com/tfQNRie8BNePhxgHlalrIgwQJfvLSexppjjCuSu3EMf7WNhdZjAok46Er3QcHfavCSfL4G-UE4dMQB26DYpflrgrvS3Wqcbq7FjlH4vXeS5idKk4N1j2KUsp9xRokFOn3vazhzgcs-0">Baader Hyperion Zoom Mark IV 8-24 mm Click Stop Eyepiece</li>
    </ul>
    <h4>Barrlow Lens</h4>
    <ul>
        <li><img src="https://images-na.ssl-images-amazon.com/images/I/51WRgS3Qi4L._AC_SX679_.jpg">MEOPTEX 1.25" 3X Barlow Lens</li>
        <li><img src="https://lh3.googleusercontent.com/cY9kj0Git2K2KMcoZkwS5rCwLYNwCRtoM8p5MBS8M9OTwwBTbhhRwXzwpO5Yd3NPtGqSPKZ3EewLi6b60X341eEOuyllLKjKATpyeSrUK7XAtrUypq05OsupVRPGKZg8U_EsCqXfw_Q">Baader Hyperion 2.25X Zoom Barlow</li>
    </ul>
    <h4>Filters</h4>
    <ul>
        <li><img src="https://lh3.googleusercontent.com/DeXhQgit8FaHH3ZODC5LVA8YXfY9zFFas7UyfaYHc2FYoi08WHzRPY3IPNbXbAFo52ksR_CL3bIBmh9G2_01ZIGEM_NrAxFNLs4KRQXbw1SxidgeXxRLk6ep8sn7LB-67ffYvoAmrAg">Baader Planetarium 1.25" Contrast Booster Filter</li>
        <li><img src="https://lh3.googleusercontent.com/2irh2u1lLkvRrhwHreMYFVuUnFfq4adzjPDPMv1Hmnsoo62fN2_riJjKRN3zAxdKLOx_3J-vPyWfdjLJ8Z76I7q1BafPFh0zFf-YYHq7It3hSIJ5LqygLDXJavTXOvzHtgAtfTGD53I">Baader Planetarium 1.25" ND 1.8 Neutral Density Filter</li>
    </ul>
    <h4>Cameras & Computers</h4>
    <ul>
        <li><img src="https://lh3.googleusercontent.com/ycc0rhr8UHOypK6Vxa0qcXn5ITTbGgn9wytsPfbggGySPzWdvTkUx86I_Ivy5QGvOc3RxcSA4Iu2ZFEn7bfbYzjG-rHoxwDL4jUfMCAyTzg8BjpBGF0zNR670YIGGzfEIcwtrfmw1jU">ZWO ASI224MC Color Imaging Camera</li>
        <li><img src="https://m.media-amazon.com/images/I/71oTy+incwL._AC_SX679_.jpg">Google Pixel 4 XL</li>
        <li><img src="https://m.media-amazon.com/images/I/61ZBAjQPNSL._AC_SL1500_.jpg">Microsoft Surface Book</li>
    </ul>
    <h4>Software</h4>
    <ul>
        <li>ZWO ASI Studio</li>
        <li>AutoStakkert!</li>
        <li>Registax</li>
        <li>Sky Portal mobile App</li>
    </ul>
</div>