<div class="mt-5 pt-5 container">
  <section id="sitebanner" class="card wow fadeIn" style="background-image: url(https://lh3.googleusercontent.com/vgYfVDjJ6N033y7ZPxwTYguIN2A_ixV-WWt-EVaKhtrb7ItnvBZ-ZCyI4oDKkTcuAQdyQ94yNEE88lxlmRDMCEgeopgnG6R3qcWn6eRn5Nvcj9HH_Z3iH176zXt6lNri6dSq2-wPfs0);">
    <div class="card-body text-white text-center py-5 px-5 my-5">
      <h1 class="mb-4">
        <strong><span class="letterhighlight">A</span>mateur<span class="letterhighlight">A</span>stronomer.Net</strong>
      </h1>
      <p>
        <strong>A place to document my struggles</strong>
      </p>
      <p class="mb-4">
        <strong>I am just getting started at Astronomy and Astrophotography and decided to create a blog site to combine all my hobbies.  My hope is by blogging you can read my failures, and not repeat my mistakes and to show off some of my photos.</strong>
      </p>
      <button type="button" mdbBtn color="primary" class="relative waves-light" (click)="basicModal.show()" mdbWavesEffect>Subscribe</button>

      <div mdbModal #basicModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close pull-right" aria-label="Close" (click)="basicModal.hide()">
                <span aria-hidden="true">×</span>
              </button>
              <h4 class="modal-title w-100" id="myModalLabel">Get the latest Blog posts</h4>
            </div>
            <div class="modal-body">
              <form [formGroup] = "subscribeForm" class="form-horizontal">
                <label for="fname">First Name:</label>
                <input class="form-control" formControlName="fname" id="fname" placeholder="Enter Your First Name..."/>
                <label for="lname">Last Name:</label>
                <input class="form-control" formControlName="lname" id="lname" placeholder="Enter Your Last Name..."/>
                <label for="email">Email Address:</label>
                <input class="form-control" type="email" formControlName="email" id="email" placeholder="Enter Your Email Address..."/>
                <div id="emailalert" class="alert alert-dismissible alert-danger" *ngIf="this.alert">
                    <button type="button" class="close" data-dismiss="alert" (click)="this.dismissAlert()">&times;</button>
                    <strong>Oh snap! </strong>Please enter a valid email address.
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" mdbBtn color="primary" class="waves-light" aria-label="Close" (click)="basicModal.hide()" mdbWavesEffect>Cancel</button>
              <button type="button" mdbBtn color="primary" class="relative waves-light" (click)="submit()" mdbWavesEffect>Subscribe</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="text-center" id="loadingmask" *ngIf="loaded == false">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <br/>Loading Please Wait...
  </div>
  <hr class="my-5" *ngIf="this.loaded == true">
  <div>
    <input id="blogsearcher" [(ngModel)]="searchText" type="text" placeholder="Search for a Topic here..." *ngIf="loaded == true">
  </div>
  <section class="text-center">
    <div class="row mb-4 wow fadeIn" >
      <div class="col-lg-4 col-md-6 mb-4" *ngFor="let blog of blogList | blogsearch: searchText | paginate: config;">
        <div class="card">
          <div class="view overlay zoom rounded" fxLayoutAlign="space-around top">
            <img  src={{blog.imageURL}} class="card-img-top" alt="">
          </div>
          <div class="card-body">
            <span class="blogdate">{{blog.createDate}}</span>
            <h4 class="card-title">{{blog.blogTitle}}</h4>
            <p class="card-text">{{blog.blogDescription}}</p>
            <a class="link-text" routerLink="/blogread/{{blog.Id}}">
              <h5 class="waves-light" mdbWavesEffect>Read more
                <mdb-icon fas icon="chevron-right" class="waves-light" mdbWavesEffect></mdb-icon>
              </h5>
            </a>
          </div>
        </div>
      </div>
    </div>
    <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
  </section>
</div>
 