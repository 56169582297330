<div id="aboutwrapper">
    <h3>About Me and this Site</h3>
    <div id="aboutinfo">
        <div id="aboutinfotext">
            <p><span class="firstletter">H</span>ello, my name is Jeffrey Heldridge.  I created this site because I like coding as well as astronomy and thought a blog would make a good double combo.  
                I will be straight up front I have only really gotten serious about astronomy and astrophotography in the last year.  I started pretty much in December 
                of 2020 to keep my self busy during covid and I have found the learning curve to be quite steep. I am trying to learn and document it all here in this 
                blog and hopfully be able to share enough that others that read it ie you wont have to go through my failures.</p>
        </div>
    </div>
    <div id="purposeinfo">
        <div id="purposeinfotext">
            <p><span class="firstletter">I</span>n this site you can find blog posts about my recent outings on the home page as well as what gear I am using, pictures I have taken, as well as any
               resources I have found.  I am hoping that the pictures get better as well as my resources over time so make sure to check back speradically of when ever you 
               might stop in to read a blog.  I always like feed back so feel free to use the contact page to hit me up via email and I will respond.  Also in advance this is 
               the most writting I have done since college so I am hoping to get better at that as well, sorry in advance lol!
            </p>
        </div>
    </div>
</div>