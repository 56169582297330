<!--Navbar-->
<mdb-navbar SideClass="navbar fixed-top navbar-expand-lg navbar-light grey scrolling-navbar " [containerInside]="false">

    <!-- Navbar brand -->
    <mdb-navbar-brand><a routerLink="/home"><img src='../../../assets/AA_Logo.png'></a><span class="letterhighlight">A</span>mateur<span class="letterhighlight">A</span>stronomer.Net</mdb-navbar-brand>
  
    <!-- Collapsible content -->
    <links>
  
      <!-- Links -->
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a routerLink="/home" class="nav-link waves-light" mdbWavesEffect>Home<span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item">
          <a routerLink="/about" class="nav-link waves-light" mdbWavesEffect>About</a>
        </li>
        <li class="nav-item">
          <a routerLink="/gear" class="nav-link waves-light" mdbWavesEffect>My Gear</a>
        </li>
        <li class="nav-item">
          <a routerLink="/gallery" class="nav-link waves-light" mdbWavesEffect>Gallery</a>
        </li>
        <li class="nav-item">
            <a routerLink="/email" class="nav-link waves-light" mdbWavesEffect>Contact</a>
        </li>
  
        <!-- Dropdown -->
        <!-- <li class="nav-item dropdown" dropdown>
          <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
            Basic dropdown<span class="caret"></span></a>
          <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
            <a class="dropdown-item waves-light" mdbWavesEffect href="#">Action</a>
            <a class="dropdown-item waves-light" mdbWavesEffect href="#">Another action</a>
            <a class="dropdown-item waves-light" mdbWavesEffect href="#">Something else here</a>
            <div class="divider dropdown-divider"></div>
            <a class="dropdown-item waves-light" mdbWavesEffect href="#">Separated link</a>
          </div>
        </li> -->
  
      </ul>
      <!-- Links -->
  
      <ul class="navbar-nav ml-auto nav-flex-icons">
        <li class="nav-item">
          <a class="nav-link" href="https://twitter.com/JMHeldridge"><i class="fab fa-twitter"></i></a>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="https://www.facebook.com/jeff.heldridge/"><i class="fab fa-facebook-square"></i></a>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="https://www.reddit.com/user/AAdotNet/"><i class="fab fa-reddit-alien"></i></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://www.instagram.com/aadotnet/"><i class="fab fa-instagram"></i></a>
        </li>
        <!-- <li class="nav-item avatar dropdown" dropdown>
          <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
            <img src="https://mdbootstrap.com/img/Photos/Avatars/avatar-2.jpg" alt="" class="img-fluid rounded-circle z-depth-0"></a>
          <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown dropdown-primary" role="menu">
            <a class="dropdown-item waves-light" mdbWavesEffect href="#">My account</a>
            <a class="dropdown-item waves-light" mdbWavesEffect href="#">Log out</a>
          </div>
        </li> -->
      </ul>
    </links>
    <!-- Collapsible content -->
  
  </mdb-navbar>
  <!--/.Navbar-->
