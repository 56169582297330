  <!--Footer-->
  <div class="page-footer text-center font-small mdb-color grey mt-4 wow fadeIn">

    <!--Call to action-->
    <!-- <div class="pt-4">
      <a class="btn btn-outline-white" href="https://mdbootstrap.com/docs/jquery/getting-started/download/" target="_blank"
        role="button">Download MDB
        <i class="fas fa-download ml-2"></i>
      </a>
      <a class="btn btn-outline-white" href="https://mdbootstrap.com/education/bootstrap/" target="_blank" role="button">Start
        free tutorial
        <i class="fas fa-graduation-cap ml-2"></i>
      </a>
    </div> -->
    <!--/.Call to action-->

    <hr class="my-4">

    <!-- Social icons -->
    <div class="pb-4">
      <a href="https://www.omahaastro.com/" target="_blank">
        Omaha Astronomy Club
      </a>
        | 
      <a href="http://www.google.com/url?q=http%3A%2F%2Fcleardarksky.com%2Fc%2FOmahaNEkey.html%3F1&sa=D&sntz=1&usg=AFQjCNGVpkyraTi44hANEHqmJceZdpIoPw" target="_blank">
        Clear Sky Omaha
      </a>
        | 
      <a href="https://www.highpointscientific.com/?msclkid=e0a30883569214fa467969f2cd04a9be" target="_blank">
        High Point Scientific
      </a>
        | 
      <a href="https://www.darkskymap.com/nightSkyBrightness" target="_blank">
        Dark Sky Map
      </a>
    </div>
    <!-- Social icons -->

    <!--Copyright-->
    <a routerLink="/blogpost">
      <img src='../../../assets/AA_Logo.png'>
    </a>
    <div class="footer-copyright py-3">
      © 2021 Copyright:
      <a href="https://jeffreyheldridge.com/" target="_blank"> 5150 Designs </a>
    </div>
    <!--/.Copyright-->
</div>