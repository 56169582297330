<div id="galleryWrapper" class="mt-5 pt-5 container">
    <section id="sitebanner" class="card wow fadeIn" style="background-image: url(https://lh3.googleusercontent.com/vgYfVDjJ6N033y7ZPxwTYguIN2A_ixV-WWt-EVaKhtrb7ItnvBZ-ZCyI4oDKkTcuAQdyQ94yNEE88lxlmRDMCEgeopgnG6R3qcWn6eRn5Nvcj9HH_Z3iH176zXt6lNri6dSq2-wPfs0);">
        <div class="card-body text-white text-center py-5 px-5 my-5">
            <h1 class="mb-4">
            <strong>Gallery</strong>
            </h1>
            <p class="mb-4">
            <strong>Below are my 30 latest photos from Instagram.  If you want you can go to Instagram and see all my pictures by clicking the link <span class="iconhighlight"><i class="fab fa-instagram"></i></span> below.  Follow me if you want just to see the results of my latest outings.</strong>
            </p>
            <p>
                <a href="https://www.instagram.com/aadotnet/"><i class="fab fa-instagram fa-2x"></i> aadotnet</a> 
            </p>
        </div>
    </section>
    <mdb-carousel [isControls]="true" class="carousel-multi-item multi-animation" [type]="'carousel-multi-item'"
        [animation]="'slide'">
        <mdb-carousel-item *ngFor="let item of slides; let i = index">
            <div class="col-md-4" [ngClass]="{'d-none d-md-block': cardIndex !== 0}"
                *ngFor="let post of item; let cardIndex = index">
                <mdb-card class="my-1">
                    <mdb-card-img [src]="post.image_url" alt="Card image cap"></mdb-card-img>
                    <div class="carousel-caption">
                        <p class="instadescript">{{post.description}}</p>
                    </div>
                </mdb-card>
            </div>
        </mdb-carousel-item>
    </mdb-carousel>
</div>
